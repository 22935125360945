import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import styles from './AcAudioPlayer.module.css';

const CustomAudioPlayer = ({
	audioUrl,
	primaryColor = '#4caf50',
	backgroundColor = '#ffffff',
	badgeColor = '#4caf50',
	badgeText = 'Enhanced',
	badgeTextColor = '#fff',
	showDownload = true,
	showBadge = true,
	downloadFileName = 'enhanced-audio.mp3',
}) => {
	return (
		<div className={styles.container} style={{ backgroundColor }}>
			{showBadge && (
				<div className={styles.badge} style={{ background: badgeColor, color: badgeTextColor }}>
					{badgeText}
				</div>
			)}
			{showDownload && (
				<div className={styles.downloadWrapper}>
					<a
						href={audioUrl}
						download={downloadFileName}
						className="btn btn-primary"
						style={{
							backgroundColor: primaryColor,
							borderColor: primaryColor,
						}}
					>
						Download
					</a>
				</div>
			)}
			<AudioPlayer autoPlay={false} src={audioUrl} style={{ '--primary-color': primaryColor }} />
		</div>
	);
};

export default CustomAudioPlayer;
