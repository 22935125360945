import React, {useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DashboardNav from '../dashboard/DashboardNav';
import { useData } from '../../context/DataContext'
import DefaultContent from "../dashboard/DefaultContent";
import UserList from "./UserList";
import UserEdit from "./UserEdit";
import UserView from "./UserView";
import Sidebar from "../../components/Sidebar";
import UserBulkCreate from "./UserBulkCreate";

const Users = (data) => {

    const { orgRole, fetchHome } = useData();
    const cData = data.data || {};
    const action = cData.cAction ? cData.cAction : "list";

    let mainComp = <DefaultContent data={orgRole}/>;
    if (action === "edit") {
        mainComp = <UserEdit/>
    } else if(action === 'list') {
        mainComp = <UserList/>
    } else if(action === 'view') {
        mainComp = <UserView/>
    } else if(action === 'bulk-user-create') {
        mainComp = <UserBulkCreate/>
    }

    useEffect(() => {
        if (!orgRole) {
            fetchHome();
        }
    }, [orgRole, fetchHome]);

    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <DashboardNav data={orgRole}/>
                </Col>
            </Row>
            <Row className="full-height">
                <Col xs={2}>
                    <Sidebar data={orgRole}/>
                </Col>
                <Col xs={10}>
                    <Container sx={{ margin: 2 }}>
                        {mainComp}
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default Users;
