import axios from 'axios';

// axios.defaults.baseURL = 'http://localhost:8000/api/v1';
// axios.defaults.baseURL = 'https://api.ablecredit.com/api/v1';
console.log("ENDPOINT from env: ", process.env.REACT_APP_API_ENDPOINT)
console.log("ENDPOINT from env: ", process.env.REACT_APP_NODE_ENV)
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = false;
//axios.defaults.headers.common['Authorization'] = '';


const jsonReq = () => {
    const instance = axios.create({
        headers: {
            'Content-Type': 'application/json',
        },
    });

    instance.interceptors.response.use(
        response => response,
        error => {
            if (error.response) {
                return Promise.reject({
                    status: error.response.status,
                    data: error.response.data,
                    headers: error.response.headers
                });
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

const formReq = () => {
    return axios.create({
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // Set the default Content-Type
        },
    })
};

const authenticateAxios = (token, orgId) => {
    axios.defaults.headers.common['Authorization'] = `bearer ${token}`;
    axios.defaults.headers.common['x-tenant-id'] = orgId;
};

export  {
    jsonReq,
    formReq,
    authenticateAxios,
}
