// src/components/BranchEdit.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Nav} from "react-bootstrap";
import Multiselect from 'multiselect-react-dropdown';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {getErrorMessage, isEmpty} from "../../utils/TextUtil";
import {jsonReq} from "../../utils/HttpUtil";
import {useData} from "../../context/DataContext";
import useFetch from "../../utils/UseFetch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import Ajv from 'ajv'
import Spinner from "react-bootstrap/Spinner";
import '../../css/dashboard.css'

function UserBulkCreate() {
    const location = useLocation();
    const navigate = useNavigate();
    const { orgRole } = useData();
    const [selOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        } else {
            return orgRole.organisation;
        }
    });
    const fileInputRef = React.useRef(null);
    const [uploadError, setUploadError] = useState(null);
    const [uploadResult, setUploadResult] = useState(null);

    const handleDownloadTemplate = async () => {
        try {
            let res = await jsonReq().post('dashboard/users/bulk-create-template', {
                org_id: selOrg._id
            }, {
                responseType: 'arraybuffer'
            });
            
            const blob = new Blob([res.data], { 
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            
            const url = window.URL.createObjectURL(blob);
            
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'user-template.xlsx');
            
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading template:', error);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('org_id', selOrg._id);

            const res = await jsonReq().post('dashboard/users/bulk-create', 
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            console.log('Upload successful:', res);
            setUploadResult(res.data);
            setUploadError(null);
        } catch (error) {
            console.error('Error uploading file:', error);
            if(error.data && error.data.msg) {
                setUploadError(error.data.msg);
            } else {
                setUploadError(error.message);
            }
        }

        // Clear the file input
        event.target.value = '';
    };

    const getTableHeaders = (users) => {
        if (!users || users.length === 0) return [];
        const headers = new Set();
        users.forEach(user => {
            Object.keys(user).forEach(key => headers.add(key));
        });
        return Array.from(headers).filter(header => 
            header !== 'user_id'
        );
    };

    const backToUserList = (ev) => {
		ev.preventDefault();
		const data = { sel_org: selOrg };
		navigate('/users/list', { state: data });
	};

    return (
		<Container>
			<Row>
				<Col xs={12} className="page-head mb-4">
					<div className="page-head">
						<span className="lead">
							Organisation - {selOrg.name} ({selOrg._id})
						</span>
						<Nav className="ml-auto justify-content-end">
							<Nav.Link href="/users/list" onClick={backToUserList}>
								<FontAwesomeIcon icon={faList} />
								&nbsp;&nbsp;List
							</Nav.Link>
						</Nav>
					</div>
				</Col>
				<Col xs={12}>
					<div className="d-flex justify-content-center align-items-center min-vh-50">
						<div className="text-center p-5 bg-light rounded-3 shadow-sm w-100">
							<h4 className="mb-4">Bulk User Management</h4>
							<div className="d-flex justify-content-center gap-4">
								<Button
									variant="primary"
									size="lg"
									className="px-5 py-3"
									onClick={handleDownloadTemplate}
								>
									Download Excel Template
								</Button>
								<input
									type="file"
									ref={fileInputRef}
									style={{ display: 'none' }}
									accept=".xlsx"
									onChange={handleFileUpload}
								/>
								<Button variant="success" size="lg" className="px-5 py-3" onClick={handleUploadClick}>
									Upload Excel
								</Button>
							</div>
						</div>
					</div>
				</Col>
				<Col xs={12} className="mt-4">
					{uploadError && (
						<Alert variant="danger" className="mt-3">
							{uploadError}
						</Alert>
					)}

					{uploadResult &&
						uploadResult.data &&
						uploadResult.data.users &&
						uploadResult.data.users.length > 0 && (
							<div className="table-responsive mt-4">
								<h5 className="mb-3">Upload Results</h5>
								<table className="table table-bordered table-hover">
									<thead className="table-light">
										<tr>
											{getTableHeaders(uploadResult.data.users).map((header) => (
												<th key={header}>
													{header
														.split('_')
														.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
														.join(' ')}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{uploadResult.data.users.map((user, index) => (
											<tr key={index} className={user.status === 'ERROR' ? 'table-danger' : ''}>
												{getTableHeaders(uploadResult.data.users).map((header) => (
													<td key={`${index}-${header}`}>
														{header === 'status' ? (
															<span
																className={`badge ${
																	user[header] === 'SUCCESS'
																		? 'bg-success'
																		: 'bg-danger'
																}`}
															>
																{user[header]}
															</span>
														) : (
															user[header]
														)}
													</td>
												))}
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
				</Col>
			</Row>
		</Container>
	);
}

export default UserBulkCreate;
